import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { api } from "../../../services/api";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function ProcuracaoPDF(unid_id, setMsg_imprimir, tipo) {

	//Variáveis
	const data = { unid_id };

	var titulo;
	var codigo;
	var texto;
	var local_data;
	var assinatura;
	var timbrado;
	var erro;
	var mensagem;

	//Pegar dados
	pegarDados(data);
	async function pegarDados(data) {
		await api.post('/processo/ProcuracaoPDF.php', JSON.stringify(data)).then(rp => {
			titulo = rp.data.titulo;
			codigo = rp.data.codigo;
			texto = rp.data.texto;
			local_data = rp.data.local_data;
			assinatura = rp.data.assinatura;
			timbrado = rp.data.timbrado;
			erro = rp.data.erro;
			mensagem = rp.data.mensagem;
		});
		gerarPDF();
	}

	//Gerar PDF
	function gerarPDF() {

		if (erro === false) {

			//Conteúdo do documento
			const conteudo = [
				{
					text: titulo + '\n\n', style: 'titulo'
				},
				{
					text: 'Processo ' + codigo + '\n\n', style: 'codigo'
				},
				texto.map((item) => {
					return [{ text: item + '\n\n', style: 'corpo' },]
				}),
				{
					text: local_data + '\n\n', style: 'data'
				},
				assinatura.map((item, i) => {
					//Se tiver apenas um item colocar em uma coluna
					if (assinatura.length === 1) {
						return [{ stack: [{ text: item, style: 'assinatura' }], unbreakable: true },]
					} else {
						//Se item par colocar em duas colunas
						if ((i % 2) === 0) {
							if ((i + 1) === assinatura.length) {
								return [{ stack: [{ text: item, style: 'assinatura' }], unbreakable: true },]
							} else {
								return ([{
									columns: [
										{ stack: [{ text: assinatura[i], style: 'assinatura' }], unbreakable: true },
										{ stack: [{ text: assinatura[i + 1], style: 'assinatura' }], unbreakable: true }
									]
								}])
							}
						}
					}
				})			
			];

			//Rodapé
			const rodape = function (currentPage, pageCount) { return ({ text: '\nPágina ' + currentPage.toString() + ' de ' + pageCount, style: 'rodape' }); };

			//Estilos
			const estilos = {
				titulo: {
					fontSize: 12,
					alignment: 'center',
					bold: true
				},
				codigo: {
					fontSize: 10,
					alignment: 'right',
					bold: false
				},
				corpo: {
					fontSize: 10,
					alignment: 'justify'
				},
				data: {
					fontSize: 10,
					alignment: 'right'
				},
				assinatura: {
					fontSize: 10,
					alignment: 'center'
				},
				rodape: {
					fontSize: 8,
					alignment: 'center'
				}
			};

			//Imagem de fundo
			var imagem;
			if (timbrado !== "") {
				imagem = { image: timbrado, width: 610 };
			} else {
				imagem = "";
			}

			//Definições
			var docDefinition = {
				pageSize: 'A4',
				pageMargins: [70, 80, 50, 70],
				info: { title: 'Procuração ' + codigo },
				background: imagem,
				content: conteudo,
				styles: estilos,
				footer: rodape
			};

			if (tipo === 'open') {
				pdfMake.createPdf(docDefinition).open();
			}
			if (tipo === 'download') {
				pdfMake.createPdf(docDefinition).download('Procuração ' + codigo);
			}

			//pdfMake.createPdf(docDefinition).download();    
			//pdfMake.createPdf(docDefinition).open({}, window);

		} else {
			setMsg_imprimir(mensagem.replaceAll(";", "; "));
		}
	}

}

export default ProcuracaoPDF;