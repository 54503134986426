import React from "react";
import { useContext, useState, useEffect } from "react";
import { Text, Button, Container, Heading, Avatar, Flex, Stack, Divider, Spacer, ButtonGroup, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useDisclosure, Grid, GridItem, Input, Select, Textarea, Box, Accordion, AccordionItem, AccordionButton, AccordionPanel, Center } from '@chakra-ui/react';
import ResizeTextarea from "react-textarea-autosize";
import { PlusSquareIcon, ChevronLeftIcon, EditIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { SaveIcon } from "../../icons/saveicon";
import { useNavigate } from "react-router-dom";
import { api } from "../../../services/api";

import NavSuperior from "../../components/NavBar/navsuperior";

import { AuthContext } from "../../contexts/auth";

const UsuariosPage = () => {
	const { logout, setUsuario_selecionado, credencial } = useContext(AuthContext);

	//Variáveis Chakra UI
	const navigate = useNavigate();
	const { isOpen: isNovoOpen, onOpen: onNovoOpen, onClose: onNovoClose } = useDisclosure();

	//Variáveis
	const [mensagem, setMensagem] = useState("");
	const [usu_nome, setUsu_nome] = useState("");
	const [usu_nascimento, setUsu_nascimento] = useState("");
	const [usu_senha, setUsu_senha] = useState("");
	const [usu_email, setUsu_email] = useState("");
	const [usu_setor, setUsu_setor] = useState("");
	const [usu_cargo, setUsu_cargo] = useState("");
	const [usu_funcao, setUsu_funcao] = useState("");
	const [usu_formacao, setUsu_formacao] = useState("");

	const [lista_setores, setLista_setores] = useState([]);
	const [lista_usuarios, setLista_usuarios] = useState([{ id: 0, nome: '-', email: '-', setor: '-', cargo: '-', formacao: '-', funcao: '-', situacao: '-' }]);

	//Verificação senha
	const [ver_senha, setVer_senha] = useState(false);
	const [repetir_senha, setRepetir_senha] = useState("");

	//Controle
	const [recarregar, setRecarregar] = useState(true);

	//Ler dados
	useEffect(() => {
		api.post('/usuario/LerListaUsuarios.php')
			.then(function (rp) {
				setLista_setores(rp.data.lista_setores);
				setLista_usuarios(rp.data.lista_usuarios);
			})
			.catch(function (error) {
				if (error.response.data === "EXPIRED") {
					logout();
				} else {
					console.log(error);
				}
			});
	}, [recarregar]);

	//Salvar dados
	async function salvarUsuario() {
		if (usu_senha === repetir_senha) {
			const data = {
				usu_nome,
				usu_nascimento,
				usu_formacao,
				usu_setor,
				usu_cargo,
				usu_funcao,
				usu_email,
				usu_senha
			}
			api.post('/usuario/NovoUsuario.php', JSON.stringify(data))
				.then(function (rp) {
					if (rp.data.sucesso === false) {
						setMensagem(rp.data.msg);
					} else {
						onNovoClose();
						setRecarregar(!recarregar);
						setMensagem("");
						setUsu_nome("");
						setUsu_nascimento("");
						setUsu_senha("");
						setUsu_email("");
						setUsu_setor("");
						setUsu_cargo("");
						setUsu_funcao("");
						setUsu_formacao("");
						setRepetir_senha("");
					}
				})
				.catch(function (error) {
					if (error.response.data === "EXPIRED") {
						logout();
					} else {
						console.log(error);
					}
				});
		} else {
			setMensagem("As senhas informadas devem ser iguais")
		}
	}

	//Editar usuário
	function handleEditar(id) {
		setUsuario_selecionado(id);
		navigate('/perfil');
	}

	//Ir para home
	function goToHome() {
		navigate('/');
	}

	return (
		<div id="usuarios">
			<NavSuperior />
			<Divider borderColor={'gray.400'} orientation='horizontal' mt={2} mb={2} />
			<Heading as='h1' size='lg' color={'gray.600'} textAlign={'center'}>
				EQUIPE
			</Heading>
			<Divider borderColor={'gray.400'} orientation='horizontal' mt={2} mb={2} />
			<Container maxW={'95%'} mb={20}>				

				<Flex direction='row' w={'100%'} h={'60px'} bg={'gray.200'}>
					<Center w={'100px'} h={'100%'} color={'gray.600'} cursor='pointer' _hover={{ color: 'teal' }} onClick={e => goToHome()}>
						<ChevronLeftIcon boxSize={12} />
					</Center>
					<Center>
						<Heading size={'md'} color={'gray.700'} ml={3}>Usuários Cadastrados ({lista_usuarios.length})</Heading>
					</Center>
					<Spacer />
					<Center>
						<ButtonGroup gap='4' mr={4}>
						<Button display={credencial === 'administrador' ? 'block' : 'none'} colorScheme="teal" variant='outline' onClick={e => onNovoOpen()}>Novo Usuário<PlusSquareIcon ml={2} /></Button>
						</ButtonGroup>
					</Center>
				</Flex>

				<Accordion allowMultiple mt={8}>
					{lista_setores.map((setor, i) => {
						return (
							<div key={i}>
								<Heading as='h4' size='md' textAlign={'left'} ml={8}>
									{setor}
								</Heading>
								<Divider borderColor={'gray.400'} orientation='horizontal' mt={4} />
								<Box mb={10} mt={2}>
									<Flex direction="column" w={'100%'}>
										<Flex direction={'row'} w={'100%'} as={'b'} color={'gray.500'}>
											<Flex w={'10%'}></Flex>
											<Flex w={'25%'}>NOME</Flex>
											<Flex w={'25%'}>E-MAIL</Flex>
											<Flex w={'20%'}>CARGO</Flex>
											<Flex w={'20%'}>SITUAÇÃO</Flex>
										</Flex>
										{lista_usuarios.map((item, j) => {
											if (item.setor === setor) {
												return (
													<div key={j}>
														<AccordionItem direction={'row'} w={'100%'} border={'none'}>
															<AccordionButton borderBottomWidth={'thin'} minH={'70px'}>
																<Flex w={'10%'}><Avatar bg={'gray.400'} color={'white'} name={item.nome} src='' /></Flex>
																<Flex w={'25%'}>{item.nome}</Flex>
																<Flex w={'25%'}>{item.email}</Flex>
																<Flex w={'20%'}>{item.cargo}</Flex>
																<Flex w={'20%'}>{item.situacao}</Flex>
															</AccordionButton>
															<AccordionPanel pt={4} mb={5} bg={'gray.100'}>
																<Flex direction="row" w={'100%'}>
																	<Flex direction={'column'} w={'25%'} ml={'10%'} pr={10}>
																		<Flex mb={1}><Text as={'b'}>Formação Profissional</Text></Flex>
																		<Flex>{item.formacao === '' ? 'Não Informado' : item.formacao}</Flex>
																	</Flex>
																	<Flex direction={'column'} w={'45%'}>
																		<Flex mb={1}><Text as={'b'}>Funções Desempenhadas</Text></Flex>
																		<Flex>{item.funcao === '' ? 'Não Informado' : item.funcao}</Flex>
																	</Flex>
																	<Center><Button display={credencial === 'administrador' ? 'block' : 'none'} colorScheme="teal" variant='outline' onClick={e => handleEditar(item.id)}>Editar<EditIcon ml={2} /></Button></Center>
																</Flex>
															</AccordionPanel>
														</AccordionItem>
													</div>
												);
											}
										})}
									</Flex>

								</Box>
							</div>
						);
					})}
				</Accordion>
			</Container>

			{/*MODAL NOVO USUÁRIO*/}
			<Modal isOpen={isNovoOpen} onClose={onNovoClose} isCentered size='6xl'>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader textAlign='center'>NOVO USUÁRIO</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Container maxW='99%'>
							<Grid
								h='40px'
								templateRows='repeat(1, 1fr)'
								templateColumns='repeat(12, 1fr)'
								gap={4}
							>
								<GridItem colSpan={8} >
									<Stack spacing={3} direction='row'><Text>Nome</Text><Text color={'red.600'}>*</Text><Input borderColor={'gray'} maxLength={220} placeholder='Nome Completo' size='sm' value={usu_nome} onChange={e => setUsu_nome(e.target.value)} /></Stack>
								</GridItem>

								<GridItem colSpan={4}>
									<Stack spacing={3} direction='row'><Text whiteSpace='nowrap'>Data de Nascimento</Text><Input autoComplete="no" borderColor={'gray'} type='date' size='sm' value={usu_nascimento} onChange={e => setUsu_nascimento(e.target.value)} /></Stack>
								</GridItem>
							</Grid>

							<Stack spacing={1} direction='column' mb={4}><Text whiteSpace='nowrap'>Formação</Text>
								<Textarea borderColor={'gray'}
									minH="unset"
									overflow="hidden"
									w="100%"
									resize="none"
									minRows={2}
									as={ResizeTextarea}
									placeholder="Descrição da formação do usuário..."
									value={usu_formacao}
									onChange={e => setUsu_formacao(e.target.value)}
								/></Stack>

							<Grid
								h='40px'
								templateRows='repeat(1, 1fr)'
								templateColumns='repeat(12, 1fr)'
								gap={4}
							>
								<GridItem colSpan={4}>
									<Stack spacing={3} direction='row'><Text whiteSpace='nowrap'>Setor</Text><Text color={'red.600'}>*</Text>
										<Select borderColor={'gray'} size='sm' value={usu_setor} onChange={e => setUsu_setor(e.target.value)}>
											<option value={''}>-</option>
											<option value={'Gestão'}>Gestão</option>
											<option value={'Jurídico'}>Jurídico</option>
											<option value={'Financeiro'}>Financeiro</option>
											<option value={'Comercial'}>Comercial</option>
											<option value={'Atendimento'}>Atendimento</option>
											<option value={'Documental'}>Documental</option>
											<option value={'Topografia'}>Topografia</option>
											<option value={'Projeto'}>Projeto</option>
											<option value={'Pós-Protocolo'}>Pós-Protocolo</option>
											<option value={'Desenvolvimento'}>Pós-Protocolo</option>
										</Select>
									</Stack>
								</GridItem>
								<GridItem colSpan={8}>
									<Stack spacing={3} direction='row'><Text>Cargo</Text><Text color={'red.600'}>*</Text><Input borderColor={'gray'} maxLength={80} size='sm' value={usu_cargo} onChange={e => setUsu_cargo(e.target.value)} /></Stack>
								</GridItem>
							</Grid>

							<Stack spacing={1} direction='column' mb={4}><Text whiteSpace='nowrap'>Funções</Text>
								<Textarea borderColor={'gray'}
									minH="unset"
									overflow="hidden"
									w="100%"
									resize="none"
									minRows={2}
									as={ResizeTextarea}
									placeholder="Descrição das funções desempenhadas pelo usuário..."
									value={usu_funcao}
									onChange={e => setUsu_funcao(e.target.value)}
								/></Stack>

							<Grid
								h='80px'
								templateRows='repeat(1, 1fr)'
								templateColumns='repeat(12, 1fr)'
								gap={4}
							>
								<GridItem colSpan={12}>
									<Stack spacing={3} direction='row'><Text whiteSpace='nowrap'>E-mail</Text><Text color={'red.600'}>*</Text><Input borderColor={'gray'} size='sm' type="email" value={usu_email} onChange={e => setUsu_email(e.target.value)} /></Stack>
								</GridItem>
								<GridItem colSpan={5}>
									<Stack spacing={3} direction='row'><Text whiteSpace='nowrap'>Senha</Text><Text color={'red.600'}>*</Text><Input borderColor={'gray'} size='sm' type={ver_senha === false ? 'password' : 'text'} value={usu_senha} onChange={e => setUsu_senha(e.target.value)} /></Stack>
								</GridItem>
								<GridItem colSpan={6}>
									<Stack spacing={3} direction='row'><Text whiteSpace='nowrap'>Repetir Senha</Text><Text color={'red.600'}>*</Text><Input borderColor={'gray'} size='sm' type={ver_senha === false ? 'password' : 'text'} value={repetir_senha} onChange={e => setRepetir_senha(e.target.value)} /></Stack>
								</GridItem>
								<GridItem colSpan={1}>
									<Button variant={'ghost'} w={'100%'} size={'sm'} onClick={e => setVer_senha(true)} display={ver_senha === false ? 'block' : 'none'}><ViewIcon color={'gray.500'} boxSize={5} /></Button>
									<Button variant={'ghost'} w={'100%'} size={'sm'} onClick={e => setVer_senha(false)} display={ver_senha === true ? 'block' : 'none'}><ViewOffIcon color={'gray.500'} boxSize={5} /></Button>
								</GridItem>
							</Grid>
						</Container>
					</ModalBody>
					<ModalFooter>
						<Text m={3} color={'red.500'} display={mensagem === "" ? 'none' : 'block'}>{mensagem}</Text>
						<Spacer />
						<Button colorScheme="teal" variant={'outline'} onClick={e => salvarUsuario()}>Salvar <SaveIcon ml={2} /></Button>
					</ModalFooter>
				</ModalContent>
			</Modal>

		</div>
	);
}

export default UsuariosPage;