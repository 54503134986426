import React from "react";
import { useContext, useState, useEffect } from "react";
import { Reorder, useDragControls } from "framer-motion";
import { Divider, Container, Heading, Button, useDisclosure, Spacer, Text, Stack, Select, Textarea, SimpleGrid, Box, Flex, Center, Grid, GridItem, Accordion, AccordionItem, AccordionButton, AccordionPanel, Tag, Collapse, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import ResizeTextarea from "react-textarea-autosize";
import { ChevronLeftIcon, ChevronRightIcon, ChevronDownIcon, PlusSquareIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { SaveIcon } from "../../icons/saveicon";

import NavSuperior from "../../components/NavBar/navsuperior";

import { api } from "../../../services/api";

import { AuthContext } from "../../contexts/auth";

const MetasPage = () => {

  //Variáveis de Contexto
  const { logout, user } = useContext(AuthContext);

  //Variáveis Chakra UI
  const cancelRef = React.useRef();
  const { isOpen: isMetaOpen, onToggle: onMetaToggle } = useDisclosure();
  const { isOpen: isExcluirOpen, onOpen: onExcluirOpen, onClose: onExcluirClose } = useDisclosure();

  //Reorder
  const controls = useDragControls();

  //Data Atual
  const dataSao_Paulo = new Date().toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' });
  const dataAtual = new Date(parseInt(dataSao_Paulo.substring(6, 10)), parseInt(dataSao_Paulo.substring(3, 5) - 1), parseInt(dataSao_Paulo.substring(0, 2)));

  //Variáveis  
  const lista_setores = ['Gestão', 'Jurídico', 'Financeiro', 'Comercial', 'Atendimento', 'Documental', 'Topografia', 'Projeto', 'Pós-Protocolo', 'Desenvolvimento'];

  const [met_ano, setMet_ano] = useState(segundaAnterior(dataAtual).getFullYear());
  const [met_mes, setMet_mes] = useState(segundaAnterior(dataAtual).getMonth() + 1);
  const [met_setor, setMet_setor] = useState("Gestão");

  const [met_inicio, setMet_inicio] = useState(dataParaTexto(segundaAnterior(dataAtual)));
  const [met_fim, setMet_fim] = useState(dataParaTexto(somarDias(segundaAnterior(dataAtual), 6)));
  const [lista_semanas, setLista_semanas] = useState(definirSemanas(met_ano, met_mes));

  const [lista_metas, setLista_metas] = useState([{ id: 0, inicio: '-', fim: '-', setor: '-', titulo: '-', situacao: '-', id_responsavel: 0, responsavel: '-', id_proponente: 0, proponente: '-', descricao: '-', observacao: '-', criado: '-', finalizado: '-', texto: '-' }]);
  const [lista_usuarios, setLista_usuarios] = useState([{ id: 0, nome: '-' }]);
  const [lista_anos, setLista_anos] = useState([]);

  const [met_titulo, setMet_titulo] = useState("");
  const [met_descricao, setMet_descricao] = useState("");
  const [met_responsavel, setMet_responsavel] = useState(0);
  const [met_situacao, setMet_situacao] = useState("");
  const [met_observacao, setMet_observacao] = useState("");

  const [editar_proponente, setEditar_proponente] = useState(0);
  const [editar_responsavel, setEditar_responsavel] = useState(0);
  const [met_excluir, setMet_excluir] = useState(0);

  const [ordem, setOrdem] = useState([1, 2]);
  const [index, setIndex] = useState([]);

  //Ler dados
  useEffect(() => {
    const data = {
      met_inicio,
      met_setor
    };
    api.post('/metas/LerMetas.php', JSON.stringify(data))
      .then(function (rp) {
        setLista_metas(rp.data.lista_metas);
        setLista_usuarios(rp.data.lista_usuarios);
        setLista_anos(rp.data.lista_anos);
      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
        }
      });
    if (isMetaOpen) {
      onMetaToggle();
      setMet_titulo("");
      setMet_descricao("");
      setMet_situacao("");
      setMet_observacao("");
      setMet_responsavel(0);
    }
  }, [met_inicio, met_setor]);

  //Recarregar lista de metas  
  async function recarregarMetas() {
    const data = {
      met_inicio,
      met_setor
    };
    api.post('/metas/LerMetas.php', JSON.stringify(data))
      .then(function (rp) {
        setLista_metas(rp.data.lista_metas);
        setLista_usuarios(rp.data.lista_usuarios);
      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
        }
      });
    if (isMetaOpen) {
      onMetaToggle();
      setMet_titulo("");
      setMet_descricao("");
      setMet_situacao("");
      setMet_observacao("");
      setMet_responsavel(0);
    }
  }

  //Definir semanas
  function definirSemanas(ano, mes) {
    //Número de semanas no mês
    const numeroSemanas = calcularSemanas(ano, mes);
    //Primeira segunda-feira do mês
    const segunda = primeiraSegunda(ano, mes);
    let semanas = [{
      semana: 1,
      inicio: dataParaTexto(segunda),
      fim: dataParaTexto(somarDias(segunda, 6))
    }];
    for (let i = 2; i <= numeroSemanas; i++) {
      semanas.push({
        semana: i,
        inicio: dataParaTexto(somarDias(segunda, 7 * (i - 1))),
        fim: dataParaTexto(somarDias(segunda, 6 * i + (i - 1)))
      });
    }
    return semanas;
  }

  //Calcular quantas semanas há no mês
  function calcularSemanas(ano, mes) {
    let contador = 0;
    const data = new Date(ano, mes - 1, 1); // Primeiro dia do mês
    // Obter o número de dias no mês
    const diasNoMes = new Date(ano, mes - 1, 0).getDate();
    for (let dia = 1; dia <= diasNoMes; dia++) {
      data.setDate(dia); // Define o dia atual
      if (data.getDay() === 1) { // Verifica se é segunda-feira
        contador++;
      }
    }
    return contador;
  }

  //Data como texto
  function dataParaTexto(data) {
    const dd = data.getDate();
    const mm = data.getMonth() + 1;
    const yyyy = data.getFullYear();
    return yyyy + '-' + mm + '-' + dd;
  }

  //Texto como data
  function textoParaData(data) {
    var parts = data.split('-');
    var data_formatada = new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2]));
    return data_formatada;
  }

  //Data por extenso
  function dataPorExtenso(data) {
    const meses = [
      "janeiro", "fevereiro", "março", "abril",
      "maio", "junho", "julho", "agosto",
      "setembro", "outubro", "novembro", "dezembro"
    ];
    const dia = data.getDate();
    const mes = meses[data.getMonth()];
    const ano = data.getFullYear();
    return `${dia} de ${mes} de ${ano}`;
  }

  //Definir primeira segunda-feira do mês
  function primeiraSegunda(ano, mes) {
    const data = new Date(ano, mes - 1, 1); // Primeiro dia do mês
    while (data.getDay() !== 1) { // Enquanto não for segunda
      data.setDate(data.getDate() + 1); // Avança um dia
    }
    return data;
  }

  //Definir a data da segunda-feira anterior
  function segundaAnterior(data) {
    var day = data.getDay();
    var prevMonday = new Date();
    if (data.getDay() === 0) { //domingo
      prevMonday.setDate(data.getDate() - 6);
    }
    else if (data.getDay() === 1) { //segunda-feira
      prevMonday = data;
    }
    else { //outro dia
      prevMonday.setDate(data.getDate() - (day - 1));
    }
    return prevMonday;
  }

  //Somar dias a uma data
  function somarDias(data, dias) {
    var novaData = new Date(data);
    novaData.setDate(data.getDate() + dias);
    return novaData;
  }

  //Subtrair dias a uma data
  function subtrairDias(data, dias) {
    var novaData = new Date(data);
    novaData.setDate(data.getDate() - dias);
    return novaData;
  }

  //Anterior
  function anterior() {
    const inicio = subtrairDias(textoParaData(met_inicio), 7);
    const fim = somarDias(inicio, 6);
    //Verificar se o ano consta na lista de anos
    if (lista_anos.includes(inicio.getFullYear())) {
      setMet_inicio(dataParaTexto(inicio));
      setMet_fim(dataParaTexto(fim));
      //Se mudar o mês
      if (met_mes !== inicio.getMonth() + 1) {
        setMet_mes(inicio.getMonth() + 1);
        setMet_ano(inicio.getFullYear());
        setLista_semanas(definirSemanas(inicio.getFullYear(), inicio.getMonth() + 1));
      }
    }
  }

  //Próximo
  function proximo() {
    const inicio = somarDias(textoParaData(met_inicio), 7);
    const fim = somarDias(inicio, 6);
    if (lista_anos.includes(inicio.getFullYear())) {
      setMet_inicio(dataParaTexto(inicio));
      setMet_fim(dataParaTexto(fim));
      //Se mudar o mês
      if (met_mes !== inicio.getMonth() + 1) {
        setMet_mes(inicio.getMonth() + 1);
        setMet_ano(inicio.getFullYear());
        setLista_semanas(definirSemanas(inicio.getFullYear(), inicio.getMonth() + 1));
      }
    }
  }

  //Mês alterado
  function alterarMes(novoMes) {
    //Primeira segunda-feira do mês
    const novoInicio = primeiraSegunda(met_ano, novoMes);
    setMet_inicio(dataParaTexto(novoInicio));
    setMet_fim(dataParaTexto(somarDias(novoInicio, 6)));
    setMet_mes(novoMes);
    setLista_semanas(definirSemanas(met_ano, novoMes));
  }

  //Ano alterado
  function alterarAno(novoAno) {
    //Primeira segunda-feira do mês
    const novoInicio = primeiraSegunda(novoAno, met_mes);
    setMet_inicio(dataParaTexto(novoInicio));
    setMet_fim(dataParaTexto(somarDias(novoInicio, 6)));
    setMet_ano(novoAno);
    setLista_semanas(definirSemanas(novoAno, met_mes));
  }

  //Salvar meta
  async function salvarNovaMeta() {
    if (met_titulo !== "") {
      const data = {
        met_inicio,
        met_fim,
        met_setor,
        met_titulo,
        met_descricao,
        met_responsavel,
        met_proponente: user
      }
      await api.post('/metas/NovaMeta.php', JSON.stringify(data))
        .then(function (rp) {

          setMet_titulo("");
          setMet_descricao("");
          setMet_responsavel(0);
          recarregarMetas();

        })
        .catch(function (error) {
          if (error.response.data === "EXPIRED") {
            logout();
          }
        });
    }
  }

  //Salvar proponente
  async function salvarProponente() {
    if (met_titulo !== "" && editar_proponente > 0) {
      const data = {
        met_id: editar_proponente,
        met_titulo,
        met_descricao,
        met_responsavel
      }
      await api.post('/metas/SalvarProponente.php', JSON.stringify(data))
        .then(function (rp) {

          setEditar_proponente(0);
          setMet_titulo("");
          setMet_descricao("");
          setMet_responsavel(0);
          recarregarMetas();

        })
        .catch(function (error) {
          if (error.response.data === "EXPIRED") {
            logout();
          }
        });
    }
  }

  //Salvar responsável
  async function salvarResponsavel() {
    if (editar_responsavel > 0) {
      const data = {
        met_id: editar_responsavel,
        met_situacao,
        met_observacao
      }
      await api.post('/metas/SalvarResponsavel.php', JSON.stringify(data))
        .then(function (rp) {

          setEditar_responsavel(0);
          setMet_observacao("");
          recarregarMetas();

        })
        .catch(function (error) {
          if (error.response.data === "EXPIRED") {
            logout();
          }
        });
    }
  }

  //Excluir meta
  async function excluirMeta() {
    if (met_excluir !== 0) {
      const data = {
        met_id: met_excluir
      }
      await api.post('/metas/ExcluirMeta.php', JSON.stringify(data))
        .then(function (rp) {

          recarregarMetas();

        })
        .catch(function (error) {
          if (error.response.data === "EXPIRED") {
            logout();
          }
        });
      setMet_excluir(0);
    }
  }

  //Usuários com meta
  function usuariosComMeta() {
    let responsaveis = [];
    let sem_responsavel = false;
    lista_metas.map((item) => {
      if (!responsaveis.some(element => element.id === item.id_responsavel)) {
        if (item.id_responsavel === 0) {
          sem_responsavel = true;
        } else {
          responsaveis.push({
            id: item.id_responsavel,
            nome: item.responsavel
          });
        }
      }
    });
    if (sem_responsavel) {
      responsaveis.push({
        id: 0,
        nome: 'Sem Responsável'
      });
    }
    return responsaveis;
  }

  return (
    <div id="metas">
      <NavSuperior />
      <Divider borderColor={'gray.400'} orientation='horizontal' mt={2} mb={2} />
      <Heading size='lg' color={'gray.600'} textAlign={'center'}>
        METAS
      </Heading>
      <Divider borderColor={'gray.400'} orientation='horizontal' mt={2} mb={2} />
      <Container maxW={'95%'} mb={20}>

        <Grid
          templateAreas={`"header semana"
            "nav main"
            "nav footer"`}
          gridTemplateRows={'1fr'}
          gridTemplateColumns={'170px 1fr'}
          gap='1'
        >
          <GridItem pl='2' bg='gray.100' area={'header'}>
            <Center h={10}>SETOR</Center>
          </GridItem>
          <GridItem pl='2' pr='2' bg='gray.100' area={'semana'}>
            <Flex>
              <Center w='150px' mr={1}>
                <Select textAlign={'center'} variant='flushed' borderColor={'gray'} size='sm' value={met_ano} onChange={e => alterarAno(e.target.value)} onClick={() => { setEditar_proponente(0); setEditar_responsavel(0); }}>
                  {lista_anos.map((item) => {
                    return (
                      <option value={item}>{item}</option>
                    );
                  })}
                </Select>
              </Center>
              <Center w='200px' mr={2}>
                <Select textAlign={'center'} variant='flushed' borderColor={'gray'} size='sm' value={met_mes} onChange={e => alterarMes(e.target.value)} onClick={() => { setEditar_proponente(0); setEditar_responsavel(0); }}>
                  <option value={1}>Janeiro</option>
                  <option value={2}>Fevereiro</option>
                  <option value={3}>Março</option>
                  <option value={4}>Abril</option>
                  <option value={5}>Maio</option>
                  <option value={6}>Junho</option>
                  <option value={7}>Julho</option>
                  <option value={8}>Agosto</option>
                  <option value={9}>Setembro</option>
                  <option value={10}>Outubro</option>
                  <option value={11}>Novembro</option>
                  <option value={12}>Dezembro</option>
                </Select>
              </Center>
              <Center w='80px' _hover={{ bg: 'gray.400' }} cursor={'pointer'} onClick={() => { setEditar_proponente(0); setEditar_responsavel(0); anterior(); }}>
                <ChevronLeftIcon boxSize={10} />
              </Center>
              <Box flex='1'>
                <SimpleGrid columns={lista_semanas.length}>
                  {lista_semanas.map((item) => {
                    return (
                      <Button w={'100%'} fontWeight={'normal'} borderRadius={0} variant={'unstyled'} h='10' bg={met_inicio === item.inicio ? 'white' : 'gray.100'} border={dataParaTexto(segundaAnterior(dataAtual)) === item.inicio? "1px solid gray":'none'} onClick={e => { setMet_inicio(item.inicio); setMet_fim(item.fim); }}>{"Semana 0" + item.semana}</Button>
                    );
                  })}
                </SimpleGrid>
              </Box>
              <Center w='80px' _hover={{ bg: 'gray.400' }} cursor={'pointer'} onClick={() => { setEditar_proponente(0); setEditar_responsavel(0); proximo(); }}>
                <ChevronRightIcon boxSize={10} />
              </Center>
            </Flex>
          </GridItem>
          <GridItem pl='2' bg='gray.100' area={'nav'}>
            {lista_setores.map((item) => {
              return (
                <Button fontWeight={'normal'} borderRadius={0} variant={'unstyled'} w={'100%'} h='10' textAlign={'right'} pr={5} cursor={'pointer'} bg={met_setor === item ? 'white' : 'gray.100'} onClick={e => { setEditar_proponente(0); setEditar_responsavel(0); setMet_setor(item); }}>{item}</Button>
              );
            })}
          </GridItem>
          <GridItem pl='2' area={'main'}>
            <Text textAlign={'center'} m={2}>De {dataPorExtenso(textoParaData(met_inicio))} a {dataPorExtenso(textoParaData(met_fim))}</Text>
            <Divider borderColor={'gray.400'} mb={3} />
            <Box flex={1} display={lista_metas[0]['id'] === 0 ? 'none' : 'block'}>
              {usuariosComMeta().map((elemento) => {
                return (
                  <>
                    <Heading size='sm' m={2} color={'gray'}>{elemento.nome}</Heading>
                    <Accordion allowMultiple>
                      {lista_metas.map((item, i) => {

                        if (elemento.id === item.id_responsavel) {
                          if (item.id !== 0) {
                            if (editar_proponente === item.id) {
                              return (
                                <>
                                  <Box border={'1px solid'} borderColor={'gray.300'} rounded={'lg'} mb={2}>
                                    <Box flex='1' bg={'blackAlpha.100'} pt={1} pb={1} pr={4} pl={4}>
                                      <Flex>
                                        <Center w={'50px'} borderRight={'1px solid'} borderColor={'gray.300'}>
                                          <Text textAlign={'center'}>{item.ordem}</Text>
                                        </Center>
                                        <Box flex='1' textAlign={'left'} ml={4}>
                                          <Textarea borderColor={'gray'} bg={'white'}
                                            p={1}
                                            borderRadius={2}
                                            minH="unset"
                                            overflow="hidden"
                                            resize="none"
                                            minRows={1}
                                            as={ResizeTextarea}
                                            placeholder="Título da meta..."
                                            value={met_titulo}
                                            onChange={e => setMet_titulo(e.target.value)}
                                            textAlign={'left'}
                                          />
                                        </Box>
                                        <Center w={'150px'}>
                                          <Spacer />
                                          <Tag colorScheme='yellow' display={item.situacao === 'Pendente' ? 'flex' : 'none'}>{item.situacao}</Tag>
                                          <Tag colorScheme='blue' display={item.situacao === 'Em Andamento' ? 'flex' : 'none'}>{item.situacao}</Tag>
                                          <Tag colorScheme='green' display={item.situacao === 'Concluída' ? 'flex' : 'none'}>{item.situacao}</Tag>
                                          <Tag colorScheme='red' display={item.situacao === 'Atrasada' ? 'flex' : 'none'}>{item.situacao}</Tag>
                                        </Center>
                                      </Flex>
                                    </Box>
                                    <Box pt={4} pb={2} pr={4} pl={4}>
                                      <Flex>
                                        <Box flex={1} minH={'70px'} p={3}>
                                          <Textarea borderColor={'gray'} bg={'white'}
                                            ml={3}
                                            p={1}
                                            borderRadius={2}
                                            minH="unset"
                                            overflow="hidden"
                                            resize="none"
                                            minRows={2}
                                            as={ResizeTextarea}
                                            placeholder="Descrição da meta..."
                                            value={met_descricao}
                                            onChange={e => setMet_descricao(e.target.value)}
                                            textAlign={'left'}
                                            fontSize={'sm'}
                                          />
                                        </Box>
                                        <Box w={'60px'}>
                                        </Box>
                                      </Flex>
                                      <Stack spacing={4} direction='row' ml={6} mb={3} mt={2}>
                                        <Text fontSize={'sm'}>RESPONSÁVEL</Text>
                                        <Select fontSize={'sm'} variant='flushed' borderColor={'gray'} size='sm' value={met_responsavel} onChange={e => setMet_responsavel(e.target.value)} w={'350px'} pb={1} pl={4}>
                                          <option id={0} value={0}></option>
                                          {lista_usuarios.map((item, i) => {
                                            return (
                                              <option id={item.ordem} value={item.id}>{item.nome}</option>
                                            );
                                          })}
                                        </Select>
                                        <Spacer />
                                        <Button colorScheme="teal" variant={'ghost'} onClick={() => { salvarProponente(); setEditar_proponente(0); recarregarMetas(); }}>Salvar<SaveIcon ml={2} /></Button>
                                      </Stack>
                                      <Flex display={item.id_responsavel === user || item.observacao !== "" ? 'block' : 'none'}>
                                        <Divider borderColor='gray.300' />
                                        <Flex>
                                          <Box flex='1' p={3}>
                                            <Text p={3} fontSize={'sm'} display={item.observacao !== "" ? 'block' : 'none'}>{item.observacao}</Text>
                                            <Text p={3} fontSize={'sm'} color={'red.500'} display={item.observacao !== "" ? 'none' : 'block'}>Sem Observação</Text>
                                          </Box>
                                          <Box w={'60px'}>
                                            <Button colorScheme="teal" variant={'ghost'} onClick={() => { setMet_situacao(item.situacao); setMet_observacao(item.observacao); setEditar_proponente(0); setEditar_responsavel(item.id); recarregarMetas(); }} display={item.id_responsavel === user ? 'block' : 'none'}><EditIcon /></Button>
                                          </Box>
                                        </Flex>
                                      </Flex>
                                      <Divider borderColor='gray.300' />
                                      <Flex>
                                        <Box flex='1' ml={3}>
                                          <Text p={3} as={'em'} color={'gray'} fontSize={'smaller'}>{item.texto}</Text>
                                        </Box>
                                        <Box w={'60px'} minH={'45px'}>
                                          <Button colorScheme="red" variant={'ghost'} mt={2} onClick={() => { setMet_excluir(item.id); onExcluirOpen(); }} display={item.id_proponente === user ? 'block' : 'none'}><DeleteIcon /></Button>
                                        </Box>
                                      </Flex>
                                    </Box>
                                  </Box>
                                </>
                              );
                            } else if (editar_responsavel === item.id) {
                              return (
                                <>
                                  <Box border={'1px solid'} borderColor={'gray.300'} rounded={'lg'} mb={2}>
                                    <Box flex='1' bg={'blackAlpha.100'} pt={2} pb={2} pr={4} pl={4}>
                                      <Flex>
                                        <Center w={'50px'} borderRight={'1px solid'} borderColor={'gray.300'}>
                                          <Text textAlign={'center'}>{item.ordem}</Text>
                                        </Center>
                                        <Box flex='1' textAlign={'left'} ml={4}>
                                          <Text>{item.titulo}</Text>
                                        </Box>
                                        <Center w={'150px'}>
                                          <Spacer />
                                          <Menu>
                                            <MenuButton colorScheme="teal" variant={'ghost'} maxH={'30px'} size={'sm'} as={Button} rightIcon={<ChevronDownIcon />}>
                                              {met_situacao}
                                            </MenuButton>
                                            <MenuList>
                                              <MenuItem onClick={() => setMet_situacao("Pendente")} display={met_situacao === 'Atrasada' ? 'none' : 'block'}><Tag colorScheme='yellow'>Pendente</Tag></MenuItem>
                                              <MenuItem onClick={() => setMet_situacao("Em Andamento")} display={met_situacao === 'Atrasada' ? 'none' : 'block'}><Tag colorScheme='blue'>Em Andamento</Tag></MenuItem>
                                              <MenuItem onClick={() => setMet_situacao("Concluída")}><Tag colorScheme='green'>Concluída</Tag></MenuItem>
                                            </MenuList>
                                          </Menu>
                                        </Center>
                                      </Flex>
                                    </Box>
                                    <Box pt={4} pb={2} pr={4} pl={4}>
                                      <Flex>
                                        <Box flex={1} p={3} pt={1} pb={1}>
                                          <Text p={3} fontSize={'sm'} display={item.descricao !== "" ? 'block' : 'none'}>{item.descricao}</Text>
                                          <Text p={3} fontSize={'sm'} color={'red.500'} display={item.descricao !== "" ? 'none' : 'block'}>Sem Descrição</Text>
                                        </Box>
                                        <Box w={'60px'}>
                                          <Button colorScheme="teal" variant={'ghost'} onClick={() => { setMet_titulo(item.titulo); setMet_descricao(item.descricao); setMet_responsavel(item.id_responsavel); setEditar_responsavel(0); setEditar_proponente(item.id); recarregarMetas(); }} display={item.id_proponente === user ? 'block' : 'none'}><EditIcon /></Button>
                                        </Box>
                                      </Flex>
                                      <Stack spacing={4} direction='row' ml={6} mb={3}>
                                        <Text fontSize={'sm'}>RESPONSÁVEL</Text>
                                        <Text w={'350px'} fontSize={'sm'} borderBottom={'1px solid gray'} pb={1} pl={4} display={item.responsavel === '-' ? 'none' : 'block'}>{item.responsavel}</Text>
                                        <Text w={'350px'} fontSize={'sm'} borderBottom={'1px solid gray'} pb={1} pl={4} color={'red.500'} display={item.responsavel === '-' ? 'block' : 'none'}>Não Definido</Text>
                                        <Spacer />
                                      </Stack>
                                      <Flex display={item.id_responsavel === user || item.observacao !== "" ? 'block' : 'none'}>
                                        <Divider borderColor='gray.300' />
                                        <Flex>
                                          <Box flex='1' p={3}>
                                            <Textarea borderColor={'gray'} bg={'white'}
                                              ml={3}
                                              p={1}
                                              borderRadius={2}
                                              minH="unset"
                                              overflow="hidden"
                                              resize="none"
                                              minRows={2}
                                              as={ResizeTextarea}
                                              placeholder="Descrição da meta..."
                                              value={met_observacao}
                                              onChange={e => setMet_observacao(e.target.value)}
                                              textAlign={'left'}
                                              fontSize={'sm'}
                                            />
                                          </Box>
                                          <Box alignItems={'baseline'} ml={4}>
                                            <Button colorScheme="teal" variant={'ghost'} onClick={() => { salvarResponsavel(); setEditar_responsavel(0); recarregarMetas(); }}>Salvar<SaveIcon ml={2} /></Button>
                                          </Box>
                                        </Flex>
                                      </Flex>
                                      <Divider borderColor='gray.300' />
                                      <Flex>
                                        <Box flex='1' ml={3}>
                                          <Text p={3} as={'em'} color={'gray'} fontSize={'smaller'}>{item.texto}</Text>
                                        </Box>
                                        <Box w={'60px'} minH={'45px'}>
                                          <Button colorScheme="red" variant={'ghost'} mt={2} onClick={() => { setMet_excluir(item.id); onExcluirOpen(); }} display={item.id_proponente === user ? 'block' : 'none'}><DeleteIcon /></Button>
                                        </Box>
                                      </Flex>
                                    </Box>
                                  </Box>
                                </>
                              );
                            } else {
                              return (
                                <>
                                  <AccordionItem border={'1px solid'} borderColor={'gray.300'} rounded={'lg'} mb={2}>
                                    <AccordionButton _expanded={{ bg: 'blackAlpha.100' }}>
                                      <Box as="span" flex='1'>
                                        <Flex>
                                          <Center w={'50px'} borderRight={'1px solid'} borderColor={'gray.300'}>
                                            <Text textAlign={'center'}>{item.ordem}</Text>
                                          </Center>
                                          <Box flex='1' textAlign={'left'} ml={4}>
                                            <Text>{item.titulo}</Text>
                                          </Box>
                                          <Center w={'150px'}>
                                            <Spacer />
                                            <Tag colorScheme='yellow' display={item.situacao === 'Pendente' ? 'flex' : 'none'}>{item.situacao}</Tag>
                                            <Tag colorScheme='blue' display={item.situacao === 'Em Andamento' ? 'flex' : 'none'}>{item.situacao}</Tag>
                                            <Tag colorScheme='green' display={item.situacao === 'Concluída' ? 'flex' : 'none'}>{item.situacao}</Tag>
                                            <Tag colorScheme='red' display={item.situacao === 'Atrasada' ? 'flex' : 'none'}>{item.situacao}</Tag>
                                          </Center>
                                        </Flex>
                                      </Box>
                                    </AccordionButton>
                                    <AccordionPanel pb={2}>
                                      <Flex>
                                        <Box flex={1} p={3} pt={1} pb={1}>
                                          <Text p={3} fontSize={'sm'} display={item.descricao !== "" ? 'block' : 'none'}>{item.descricao}</Text>
                                          <Text p={3} fontSize={'sm'} color={'red.500'} display={item.descricao !== "" ? 'none' : 'block'}>Sem Descrição</Text>
                                        </Box>
                                        <Box w={'60px'}>
                                          <Button colorScheme="teal" variant={'ghost'} onClick={() => { setMet_titulo(item.titulo); setMet_descricao(item.descricao); setMet_responsavel(item.id_responsavel); setEditar_responsavel(0); setEditar_proponente(item.id); recarregarMetas(); }} display={item.id_proponente === user ? 'block' : 'none'}><EditIcon /></Button>
                                        </Box>
                                      </Flex>
                                      <Stack spacing={4} direction='row' ml={6} mb={3}>
                                        <Text fontSize={'sm'}>RESPONSÁVEL</Text>
                                        <Text w={'350px'} fontSize={'sm'} borderBottom={'1px solid gray'} pb={1} pl={4} display={item.responsavel === '-' ? 'none' : 'block'}>{item.responsavel}</Text>
                                        <Text w={'350px'} fontSize={'sm'} borderBottom={'1px solid gray'} pb={1} pl={4} color={'red.500'} display={item.responsavel === '-' ? 'block' : 'none'}>Não Definido</Text>
                                        <Spacer />
                                      </Stack>
                                      <Flex display={item.id_responsavel === user || item.observacao !== "" ? 'block' : 'none'}>
                                        <Divider borderColor='gray.300' />
                                        <Flex>
                                          <Box flex='1' p={3}>
                                            <Text p={3} fontSize={'sm'} display={item.observacao !== "" ? 'block' : 'none'}>{item.observacao}</Text>
                                            <Text p={3} fontSize={'sm'} color={'red.500'} display={item.observacao !== "" ? 'none' : 'block'}>Sem Observação</Text>
                                          </Box>
                                          <Box w={'60px'}>
                                            <Button colorScheme="teal" variant={'ghost'} onClick={() => { setMet_situacao(item.situacao); setMet_observacao(item.observacao); setEditar_proponente(0); setEditar_responsavel(item.id); recarregarMetas(); }} display={item.id_responsavel === user ? 'block' : 'none'}><EditIcon /></Button>
                                          </Box>
                                        </Flex>
                                      </Flex>
                                      <Divider borderColor='gray.300' />
                                      <Flex>
                                        <Box flex='1' ml={3}>
                                          <Text p={3} as={'em'} color={'gray'} fontSize={'smaller'}>{item.texto}</Text>
                                        </Box>
                                        <Box w={'60px'} minH={'45px'}>
                                          <Button colorScheme="red" variant={'ghost'} mt={2} onClick={() => { setMet_excluir(item.id); onExcluirOpen(); }} display={item.id_proponente === user ? 'block' : 'none'}><DeleteIcon /></Button>
                                        </Box>
                                      </Flex>
                                    </AccordionPanel>
                                  </AccordionItem>
                                </>
                              );
                            }
                          }
                        }
                      })}
                    </Accordion >
                  </>
                )
              })
              }
            </Box>
            <Center>
              <Button colorScheme='gray' variant={'ghost'} w={'80%'} mt={3} onClick={() => { setEditar_proponente(0); setEditar_responsavel(0); onMetaToggle(); }} display={isMetaOpen ? 'none' : 'block'}><PlusSquareIcon mr={5} />Adicionar Meta</Button>
            </Center>
            <Collapse in={isMetaOpen} animateOpacity>
              <Box border={'1px solid'} borderColor={'gray.300'} rounded={'lg'} mb={2}>
                <Box flex='1' pt={4} pb={4} pr={4} pl={4}>
                  <Textarea
                    border={'1px solid gray'}
                    _focus={{ boxShadow: 'none' }}
                    p={1}
                    borderRadius={2}
                    minH="unset"
                    overflow="hidden"
                    resize="none"
                    minRows={1}
                    as={ResizeTextarea}
                    placeholder="Título da meta..."
                    value={met_titulo}
                    onChange={e => setMet_titulo(e.target.value)}
                    textAlign={'left'}
                  />
                </Box>
                <Box pr={4} pl={4}>
                  <Textarea
                    border={'1px solid gray'}
                    _focus={{ boxShadow: 'none' }}
                    p={1}
                    borderRadius={2}
                    minH="unset"
                    overflow="hidden"
                    resize="none"
                    minRows={2}
                    as={ResizeTextarea}
                    placeholder="Descrição da meta..."
                    value={met_descricao}
                    onChange={e => setMet_descricao(e.target.value)}
                    textAlign={'left'}
                    fontSize={'sm'}
                  />
                  <Stack spacing={4} direction='row' mt={4} mb={4} ml={4}>
                    <Text fontSize={'sm'}>RESPONSÁVEL</Text>
                    <Select fontSize={'sm'} variant='flushed' borderColor={'gray'} size='sm' value={met_responsavel} onChange={e => setMet_responsavel(e.target.value)} w={'350px'} pb={1} pl={4}>
                      <option id={0} value={0}>Não Definido</option>
                      {lista_usuarios.map((item, i) => {
                        return (
                          <option id={item.ordem} value={item.id}>{item.nome}</option>
                        );
                      })}
                    </Select>
                    <Spacer />
                    <Button colorScheme="teal" variant={'ghost'} onClick={() => salvarNovaMeta()} isDisabled={met_titulo === "" ? true : false}>Salvar<SaveIcon ml={2} /></Button>
                  </Stack>
                </Box>
              </Box>
            </Collapse>
          </GridItem >
        </Grid>

      </Container>

      {/*ALERT EXCLUIR*/}
      <AlertDialog isOpen={isExcluirOpen} leastDestructiveRef={cancelRef.current} onClose={onExcluirClose} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Excluir Meta
            </AlertDialogHeader>

            <AlertDialogBody>
              Tem certeza que deseja excluir a meta?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef.current} onClick={onExcluirClose}>
                Cancelar
              </Button>
              <Button colorScheme='red' onClick={(e) => { excluirMeta(); onExcluirClose(); }} ml={3}>
                Excluir
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </div >
  );
}

export default MetasPage;