import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { api } from "../../../services/api";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function CompromissoPDF(unid_id, setMsg_imprimir, tipo) {

	//Variáveis
	const data = { unid_id };

	var conteudo;
	var obrigacao;
	var modalidade;
	var titulo;
	var codigo;
	var texto_i;
	var texto_ii;
	var texto_iii;
	var tabela_i;
	var tabela_ii;
	var local_data;
	var assinatura;
	var erro;
	var mensagem;

	//Pegar dados
	pegarDados(data);
	async function pegarDados(data) {
		await api.post('/nucleo/CompromissoPDF.php', JSON.stringify(data)).then(rp => {
			obrigacao = rp.data.obrigacao;
			modalidade = rp.data.modalidade;
			titulo = rp.data.titulo;
			codigo = rp.data.codigo;
			texto_i = rp.data.texto_i;
			texto_ii = rp.data.texto_ii;
			texto_iii = rp.data.texto_iii;
			tabela_i = rp.data.tabela_i;
			tabela_ii = rp.data.tabela_ii;
			local_data = rp.data.local_data;
			assinatura = rp.data.assinatura;
			erro = rp.data.erro;
			mensagem = rp.data.mensagem;
		});
		gerarPDF();
	}

	//Gerar PDF
	function gerarPDF() {

		if (erro === false) {

			//Conteúdo do documento			
			if (obrigacao === 'social_ambos') {
				conteudo = [
					{
						fillColor: '#e6e6e6',
						alignment: 'center',
						table: {
							widths: ['auto'],
							headerRows: 1,
							body: [
								[{ text: titulo, fontSize: 11, margin: [2, 5, 2, 5] }]
							]
						}
					},
					{
						text: '\n\n' + 'Unidade Imobiliária ' + codigo + '\n\n', style: 'codigo'
					},
					texto_i.map((item) => {
						return [{ text: item + '\n\n', style: 'corpo' },]
					}),
					{
						style: 'tableExample',
						table: {
							alignments: ['center', 'left', 'left'],
							widths: [12, 'auto', 'auto'],
							headerRows: 1,
							//dontBreakRows: true,
							keepWithHeaderRows: 1,
							body: JSON.parse(tabela_i)
						}
					},
					texto_ii.map((item) => {
						return [{ text: item + '\n\n', style: 'corpo' },]
					}),
					{
						style: 'tableExample',
						table: {
							alignments: ['center', 'left', 'left'],
							widths: [15, 'auto', 'auto'],
							headerRows: 1,
							//dontBreakRows: true,
							keepWithHeaderRows: 1,
							body: JSON.parse(tabela_ii)
						}
					},
					texto_iii.map((item) => {
						return [{ text: item + '\n\n', style: 'corpo' },]
					}),
					{
						text: local_data + '\n\n', style: 'data'
					},
					assinatura.map((item, i) => {
						//Se tiver apenas um item colocar em uma coluna
						if (assinatura.length === 1) {
							return [{ stack: [{ text: item, style: 'assinatura' }], unbreakable: true },]
						} else {
							//Se item par colocar em duas colunas
							if (i % 2 === 0) {
								return ([{
									columns: [
										{ stack: [{ text: assinatura[i], style: 'assinatura' }], unbreakable: true },
										{ stack: [{ text: assinatura[i + 1], style: 'assinatura' }], unbreakable: true }
									]
								}])
							} else {
								//Se último item
								if (i + 1 === assinatura.length) {
									return [{ stack: [{ text: item, style: 'assinatura' }], unbreakable: true },]
								}
							}
						}
					}),
					{
						columns: [
							{
								text: '\n\n\nTESTEMUNHA: ' + '\nCPF:', style: 'corpo'
							},
							{
								text: '\n\n\nTESTEMUNHA: ' + '\nCPF:', style: 'corpo'
							}
						]
					}
				];
			} else {
				conteudo = [
					{
						fillColor: '#e6e6e6',
						alignment: 'center',
						table: {
							widths: ['auto'],
							headerRows: 1,
							keepWithHeaderRows: 1,
							body: [
								[{ text: titulo, fontSize: 11, margin: [2, 5, 2, 5] }]
							]
						}
					},
					{
						text: '\n\n' + 'Unidade Imobiliária ' + codigo + '\n\n', style: 'codigo'
					},
					texto_i.map((item) => {
						return [{ text: item + '\n\n', style: 'corpo' },]
					}),
					{
						style: 'tableExample',
						table: {
							alignments: ['center', 'left', 'left'],
							widths: [15, 'auto', 'auto'],
							headerRows: 1,
							keepWithHeaderRows: 1,
							body: JSON.parse(tabela_i)
						}
					},
					texto_ii.map((item) => {
						return [{ text: item + '\n\n', style: 'corpo' },]
					}),
					{
						text: local_data + '\n\n', style: 'data'
					},
					assinatura.map((item, i) => {
						//Se tiver apenas um item colocar em uma coluna
						if (assinatura.length === 1) {
							return [{ stack: [{ text: item, style: 'assinatura' }], unbreakable: true },]
						} else {
							//Se item par colocar em duas colunas
							if ((i % 2) === 0) {
								if ((i + 1) === assinatura.length) {
									return [{ stack: [{ text: item, style: 'assinatura' }], unbreakable: true },]
								} else {
									return ([{
										columns: [
											{ stack: [{ text: assinatura[i], style: 'assinatura' }], unbreakable: true },
											{ stack: [{ text: assinatura[i + 1], style: 'assinatura' }], unbreakable: true }
										]
									}])
								}
							}
						}
					}),
					{
						columns: [
							{
								text: '\n\n\nTESTEMUNHA: ' + '\nCPF:', style: 'corpo'
							},
							{
								text: '\n\n\nTESTEMUNHA: ' + '\nCPF:', style: 'corpo'
							}
						]
					}
				];
			}

			//Rodapé
			const rodape = function (currentPage, pageCount) { return ({ text: '\nPágina ' + currentPage.toString() + ' de ' + pageCount, style: 'rodape' }); };

			//Estilos
			const estilos = {
				titulo: {
					fontSize: 12,
					alignment: 'center',
					bold: true,
					background: 'gray'
				},
				codigo: {
					fontSize: 10,
					alignment: 'right',
					bold: false
				},
				corpo: {
					fontSize: 10,
					alignment: 'justify'
				},
				tableExample: {
					fontSize: 10,
					margin: [0, 5, 0, 15]
				},
				tableHeader: {
					alignment: 'center',
					bold: false,
					fontSize: 10,
					color: 'black'
				},
				data: {
					fontSize: 10,
					alignment: 'right'
				},
				assinatura: {
					fontSize: 10,
					alignment: 'center'
				},
				rodape: {
					fontSize: 8,
					alignment: 'center'
				}
			};

			//Definições
			var docDefinition = {
				pageSize: 'A4',
				pageMargins: [70, 80, 50, 70],
				info: { title: 'Termo de Compromisso ' + codigo },
				pageBreakBefore: (currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) => {
					if (previousNodesOnPage.length <= 0 || nodesOnNextPage.length <= 0) {
						return false;
					}
					if (currentNode.pageNumbers.length === 2 && currentNode.startPosition.top > currentNode.startPosition.pageInnerHeight / 2) {
						return true;
					}
					return false;
				},
				content: conteudo,
				styles: estilos,
				footer: rodape
			};

			if (tipo === 'open') {
				pdfMake.createPdf(docDefinition).open();
			}
			if (tipo === 'download') {
				pdfMake.createPdf(docDefinition).download(codigo + ' - TC ' + modalidade);
			}

			//pdfMake.createPdf(docDefinition).download();    
			//pdfMake.createPdf(docDefinition).open({}, window);

		} else {
			setMsg_imprimir(mensagem.replaceAll(";", "; "));
		}
	}

}

export default CompromissoPDF;