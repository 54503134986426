import React from "react";
import { useContext, useState, useEffect } from "react";
import { Grid, GridItem, Text, Button, Flex, SimpleGrid, Center, Heading, Container, Spacer, ButtonGroup, Box, Stack } from '@chakra-ui/react';
import { useNavigate } from "react-router-dom";
import { ChevronLeftIcon, SettingsIcon } from "@chakra-ui/icons";
import { NucleoIcon } from "../../icons/nucleoicon";
import { ProcessoIcon } from "../../icons/processoicon";
import { RequerenteIcon } from "../../icons/requerenteicon";
import { UploadIcon } from "../../icons/uploadicon";
import { DocumentalIcon } from "../../icons/documentalicon";
import { QualificacaoIcon } from "../../icons/qualificacaoicon";
import { ModalidadeIcon } from "../../icons/modalidadeicon";
import { FinanceiroIcon } from "../../icons/financeiroicon";
import { TopografiaIcon } from "../../icons/topografiaicon";
import { PdfIcon } from "../../icons/pdficon";

import NavSuperior from "../../components/NavBar/navsuperior";

import { AuthContext } from "../../contexts/auth";
import { ReurbContext } from "../../contexts/reurb";

import { api } from "../../../services/api";

const RemessaPage = () => {
  const navigate = useNavigate();

  //Variáveis do Contexto
  const { logout } = useContext(AuthContext);
  const { rem_id, rem_municipio, rem_remessa } = useContext(ReurbContext);

  //Variáveis Remessa
  const [nucleos, setNucleos] = useState(0);
  const [processos, setProcessos] = useState(0);
  const [requerentes, setRequerentes] = useState(0);

  //Ler dados
  useEffect(() => {
    api.post('/remessa/LerRemessa.php', JSON.stringify({ rem_id }))
      .then(function (rp) {
        setNucleos(rp.data.nucleos);
        setProcessos(rp.data.processos);
        setRequerentes(rp.data.requerentes);
      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
        }
      });
  }, []);

  //Ir para home
  function goToRemessas() {
    navigate('/reurb');
  }

  //Ir para lista de núcleos
  function goToNucleos() {
    navigate('/nucleos');
  }

  //Ir para lista de processos
  function goToProcessos() {
    navigate('/processos');
  }

  //Ir para lista de requerentes
  function goToRequerentes() {
    navigate('/requerentes');
  }

  //Ir para configurações
  function goToConfiguracoes() {
    navigate('/configuracoes');
  }

  //Ir para importar
  function goToImportar() {
    navigate('/importar');
  }

  //Ir para documental
  function goToDocumental() {
    navigate('/documental');
  }

  //Ir para topografia
  function goToTopografia() {
    navigate('/topografia');
  }

  //Ir para modalidade
  function goToModalidade() {
    navigate('/modalidade');
  }

  //Ir para qualificação
  function goToQualificacao() {
    navigate('/qualificacao');
  }

  //Ir para financeiro
  function goToFinanceiro() {
    navigate('/financeiro');
  }

  //Ir para financeiro
  function goToGerarPDF() {
    navigate('/gerarpdf');
  }

  return (
    <div id="remessa">
      <NavSuperior />
      <Container maxW={'95%'} mb={20}>

        <Flex w={'100%'} h={'200px'} bg={'antiquewhite'} pt={10}>
          <Box w={'20%'} h={'100%'}><Button colorScheme="gray" variant={'ghost'} _hover={{ bg: 'none', color: 'yellow.700' }} w={'50%'} rounded={'none'} h={'100%'} onClick={e => goToRemessas()}><ChevronLeftIcon boxSize={20} /></Button>
          </Box>
          <Spacer />
          <Box textAlign={'center'}>
            <Heading color={'gray.600'}>{rem_municipio} {rem_remessa}</Heading>            
          </Box>
          <Spacer />
          <Box w={'20%'}>
            <Stack gap='4' dir="column" textAlign={'right'} mr={'50px'}>
              <Box color={'gray.600'} cursor='pointer' _hover={{ color: 'yellow.700' }} onClick={e => goToImportar()}><UploadIcon boxSize={12} /></Box>
              <Box color={'gray.600'} cursor='pointer' _hover={{ color: 'yellow.700' }} onClick={e => goToConfiguracoes()}><SettingsIcon boxSize={12} /></Box>
            </Stack>
          </Box>
        </Flex>

        <Center mt={-20}>          
          <ButtonGroup spacing={10}>
            <Button colorScheme={'gray'} variant={'solid'} h={'200px'} w={'350px'} rounded={0} boxShadow={'lg'} onClick={e => goToRequerentes()}>
              <Flex direction={'column'} gap={3}>
                <Center><RequerenteIcon boxSize={16} /></Center>
                <Center><Heading size='md' color={'gray'}>Requerentes</Heading></Center>
                <Center><Text fontSize={'4xl'} color={'black'}>{requerentes}</Text></Center>
              </Flex>
            </Button>
            <Button colorScheme={'gray'} variant={'solid'} h={'200px'} w={'350px'} rounded={0} boxShadow={'lg'} onClick={e => goToProcessos()}>
              <Flex direction={'column'} gap={3}>
                <Center><ProcessoIcon boxSize={16} /></Center>
                <Center><Heading size='md' color={'gray'}>Processos</Heading></Center>
                <Center><Text fontSize={'4xl'} color={'black'}>{processos}</Text></Center>
              </Flex>
            </Button>
            <Button colorScheme={'gray'} variant={'solid'} h={'200px'} w={'350px'} rounded={0} boxShadow={'lg'} onClick={e => goToNucleos()}>
              <Flex direction={'column'} gap={3}>
                <Center><NucleoIcon boxSize={16} /></Center>
                <Center><Heading size='md' color={'gray'}>Núcleos</Heading></Center>
                <Center><Text fontSize={'4xl'} color={'black'}>{nucleos}</Text></Center>
              </Flex>
            </Button>
          </ButtonGroup>          
        </Center>        

        <Center w={'100%'} mt={10}>
          <ButtonGroup spacing={10}>
            <Button colorScheme={'teal'} variant={'ghost'} h={'80px'} w={'350px'} rounded={0} onClick={e => goToDocumental()}>
              <Flex direction={'row'} gap={5}>
                <Center><DocumentalIcon boxSize={10} /></Center>
                <Center><Heading size='sm' color={'teal'}>Documental</Heading></Center>
              </Flex>
            </Button>
            <Button colorScheme={'teal'} variant={'ghost'} h={'80px'} w={'350px'} rounded={0} onClick={e => goToTopografia()}>
              <Flex direction={'row'} gap={5}>
                <Center><TopografiaIcon boxSize={10} /></Center>
                <Center><Heading size='sm' color={'teal'}>Topografia</Heading></Center>
              </Flex>
            </Button>
            <Button colorScheme={'teal'} variant={'ghost'} h={'80px'} w={'350px'} rounded={0} onClick={e => goToQualificacao()}>
              <Flex direction={'row'} gap={5}>
                <Center><QualificacaoIcon boxSize={10} /></Center>
                <Center><Heading size='sm' color={'teal'}>Qualificação</Heading></Center>
              </Flex>
            </Button>
          </ButtonGroup>
        </Center>

        <Center w={'100%'} mt={5}>
          <ButtonGroup spacing={10}>
            <Button colorScheme={'teal'} variant={'ghost'} h={'80px'} w={'350px'} rounded={0} onClick={e => goToModalidade()}>
              <Flex direction={'row'} gap={5}>
                <Center><ModalidadeIcon boxSize={10} /></Center>
                <Center><Heading size='sm' color={'teal'}>Modalidade</Heading></Center>
              </Flex>
            </Button>
            <Button colorScheme={'teal'} variant={'ghost'} h={'80px'} w={'350px'} rounded={0} onClick={e => goToFinanceiro()}>
              <Flex direction={'row'} gap={5}>
                <Center><FinanceiroIcon boxSize={10} /></Center>
                <Center><Heading size='sm' color={'teal'}>Financeiro</Heading></Center>
              </Flex>
            </Button>
            <Button colorScheme={'teal'} variant={'ghost'} h={'80px'} w={'350px'} rounded={0} onClick={e => goToGerarPDF()}>
              <Flex direction={'row'} gap={5}>
                <Center><PdfIcon boxSize={10} /></Center>
                <Center><Heading size='sm' color={'teal'}>Gerar PDF</Heading></Center>
              </Flex>
            </Button>
          </ButtonGroup>
        </Center>
      </Container>
    </div>
  );
}

export default RemessaPage;